import styled from "styled-components";

const Container = styled.section`
  position: relative;
  padding: 4rem 0rem;
  background-color: #f5fbff;

  @media (max-width: 1249px) {
    padding: 2rem;
  }

  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

const Heading = styled.h2`
  color: #003566;
  font-size: 3.5rem;
  text-align: center;
  font-weight: 300;
  width: 100%;
  font-family: "DM Serif Display", serif;

  @media (max-width: 1249px) {
    font-size: 2.5rem;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Content = styled.div`
  width: 100%;
  margin: 4rem auto;
  display: flex;
  flex-wrap: wrap;
  padding-left: 2rem;
  padding-right: 2rem;
  gap: 2rem;

  @media (max-width: 1249px) {
    margin: 2rem auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  flex: 2;
  min-width: 620px;

  @media (max-width: 1249px) {
    width: 100%;
    min-width: 100%;
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @media (max-width: 1249px) {
      gap: 1rem;
    }
  }

  p {
    color: #003566;
    text-align: left;
    font-size: 1.2rem;

    @media (max-width: 1249px) {
      font-size: 1rem;
    }
  }

  button {
    background-color: #c4e5f2;
    border: none;
    outline: none;
    border-radius: 100rem;
    padding: 1rem 1.8rem;
    font-size: 0.95rem;
    font-weight: 500;
    cursor: pointer;
    width: 170px;
    margin-top: 4rem;
    color: black;

    @media (max-width: 1249px) {
      margin-top: 1rem;
    }

    &:hover {
      background-color: #ffd60a;
    }
  }
`;

const RightContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  img {
    width: 80%;
    min-width: 500px;
    height: auto;

    @media (max-width: 768px) {
      width: 80%;
      min-width: 80%;
    }
  }
`;

const EightSection = () => {
  return (
    <Container>
      <Heading>
        You need a lead
        <br />
        generation partner. Not
        <br />
        another agency.
      </Heading>

      <Content>
        <LeftContainer>
          <div>
            <p>
              The traditional agency usually provides a "one-size-fits-all"
              approach
            </p>
            <p style={{ fontWeight: 600 }}>We're different.</p>
            <p>
              We take a "we do it <span style={{ fontWeight: 600 }}>with</span>{" "}
              you" mentality, acting as a true, long-term partner.
            </p>
            <p>
              We have a proven track record of propelling brands to success
              through our integrated, collaborative approach.
            </p>

            <button>About Us</button>
          </div>
        </LeftContainer>
        <RightContainer>
          <img src="/puzzle.png" alt="Puzzle Image" />
        </RightContainer>
      </Content>
    </Container>
  );
};

export default EightSection;
