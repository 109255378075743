import styled from "styled-components";

const Container = styled.section`
  position: relative;
  padding: 4rem 2rem;

  @media (max-width: 1249px) {
    padding: 2rem 1rem;
  }
`;

const Heading = styled.h2`
  color: #003566;
  font-size: 3.5rem;
  text-align: center;
  font-weight: 500;
  width: 100%;
  font-family: "DM Serif Display", serif;

  @media (max-width: 1249px) {
    font-size: 2.5rem;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Description = styled.p`
  color: #003566;
  font-size: 1.6rem;
  margin-top: 2rem;
  text-align: center;
  font-weight: 300;
  width: 100%;
  line-height: 2.2rem;

  @media (max-width: 1249px) {
    font-size: 1.2rem;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 1.4rem;
  }
`;

const TilesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 4rem;
  width: 75%;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    width: 98%;
  }
`;

const Tile = styled.div`
  background-color: #fafafa;
  border-radius: 1rem;
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  flex: 1;
  min-width: 600px;

  @media (max-width: 768px) {
    min-width: 100%;
    padding: 1.5rem;
  }

  img {
    width: 48px;
  }

  h3 {
    color: #003566;
    font-size: 2.2rem;
    font-weight: 500;
    width: 100%;

    @media (max-width: 1249px) {
      font-size: 1.6rem;
    }

    @media (max-width: 768px) {
      font-size: 1.2rem;
    }
  }

  p {
    color: #003566;
    font-size: 1rem;
    font-weight: 300;
    width: 100%;
    line-height: 1.5rem;

    @media (max-width: 768px) {
      font-size: 0.9rem;
      line-height: 1.4rem;
    }
  }
`;

const FourthSection = () => {
  return (
    <Container>
      <Heading>
        Take a look at our core <br /> Values
      </Heading>
      <Description>
        At Media Resource Group, our core values are the guiding principles that
        define who we are and how we work.
      </Description>

      <TilesContainer>
        <Tile>
          <img
            alt="Icon"
            src="https://cdn.prod.website-files.com/65272808fd070a45d9fa34ba/652e86389790fb23e28d573a_Screenshot%202023-10-17%20at%208.02.25%20AM.png"
          />
          <h3>Trust</h3>
          <p>
            We prioritize transparency, reliability, and open communication to
            build and maintain trust with
            <br />
            clients, partners, and team members.
          </p>
        </Tile>
        <Tile>
          <img
            alt="Icon"
            src="https://cdn.prod.website-files.com/65272808fd070a45d9fa34ba/652e856e54b5b517f3fed559_Screenshot%202023-10-17%20at%208.00.19%20AM.png"
          />
          <h3>Client-Centric</h3>
          <p>
            We are dedicated to understanding your unique needs, providing
            exceptional service within the time frames discussed, and
            <br />
            delivering results that exceed expectations.
          </p>
        </Tile>
        <Tile>
          <img
            alt="Icon"
            src="https://cdn.prod.website-files.com/65272808fd070a45d9fa34ba/652e859755f230d5b91a9378_Screenshot%202023-10-17%20at%208.01.05%20AM.png"
          />
          <h3>Innovation</h3>
          <p>
            We foster a culture of innovation that encourages our team to work
            with yours, to explore new ideas, <br /> embrace change, and push
            the boundaries of what's possible in advertising and marketing.
          </p>
        </Tile>
        <Tile>
          <img
            alt="Icon"
            src="https://cdn.prod.website-files.com/65272808fd070a45d9fa34ba/652e85c49bdb18a8a544dbaf_Screenshot%202023-10-17%20at%208.01.51%20AM.png"
          />
          <h3>Teamwork</h3>
          <p>
            Collaboration is essential to our success. We believe in the power
            of teamwork and the synergy
            <br />
            that comes from working together to achieve common goals.
            Communication is at the center of our success.
          </p>
        </Tile>
      </TilesContainer>
    </Container>
  );
};

export default FourthSection;
