import styled from "styled-components";
import { trackClick } from "../../utils";

const Container = styled.section`
  background-color: #003566;
  padding: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1249px) {
    padding: 2rem;
  }

  @media (max-width: 768px) {
    padding: 1.5rem;
  }
`;

const Content = styled.div`
  width: 70%;
  margin: 0 auto;
  height: 340px;
  border-radius: 3rem;
  background-image: url("/tlo.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 2rem 4rem;

  @media (max-width: 1249px) {
    padding: 1rem;
    width: 100%;
    height: 200px;
  }
`;

const Heading = styled.h2`
  color: #003566;
  font-size: 3.4rem;
  font-weight: 300;
  font-family: "DM Serif Display", serif;

  @media (max-width: 1249px) {
    font-size: 2.6rem;
  }

  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

const Button = styled.button`
  background-color: #c4e5f2;
  border: none;
  outline: none;
  border-radius: 100rem;
  padding: 1rem 1.8rem;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  width: 170px;
  margin-top: 4rem;
  color: black;

  @media (max-width: 1249px) {
    margin-top: 1rem;
    width: 140px;
    padding: 0.75rem;
    font-size: 0.85rem;
  }

  &:hover {
    background-color: #ffd60a;
  }
`;

const ThirdSection = ({ scrollToContactForm }) => {
  return (
    <Container>
      <Content>
        <Heading>
          Ready to take your
          <br />
          business to the next level?
        </Heading>
        <Button
          onClick={() => {
            trackClick("button", "Contact Us Button");
            scrollToContactForm();
          }}
        >
          Contact Us
        </Button>
      </Content>
    </Container>
  );
};

export default ThirdSection;
