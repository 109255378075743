import styled, { keyframes } from "styled-components";
import { trackClick } from "../../utils";

const slideInFromBottom = keyframes`
  0% {
    transform: translateY(5%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const Container = styled.section`
  position: relative;
  padding-bottom: 8rem;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    padding-bottom: 2rem;
  }
`;

const ContainerLeft = styled.div`
  width: 65%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${slideInFromBottom} 0.25s ease-out forwards;

  @media (max-width: 1249px) {
    width: 100%;
  }
`;

const ContainerLeftContent = styled.div`
  max-width: 38rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media (max-width: 1249px) {
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 100%;
    padding: 1rem 2rem;
  }

  @media (max-width: 768px) {
    padding: 1rem;
  }

  h1 {
    font-size: 4.2rem;
    line-height: 1.1;
    color: #003566;
    font-weight: 400;
    font-family: "DM Serif Display", serif;

    @media (max-width: 768px) {
      font-size: 3rem;
    }
  }

  p {
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 300;
    color: #003566;

    @media (max-width: 1249px) {
      width: 65%;
    }

    @media (max-width: 768px) {
      font-size: 1rem;
      line-height: 1.4rem;
      width: 100%;
    }
  }

  button {
    background-color: #ffd60a;
    border: none;
    outline: none;
    border-radius: 100rem;
    padding: 1rem 1.8rem;
    font-size: 0.95rem;
    font-weight: 500;
    cursor: pointer;
    width: 170px;
    color: black;

    &:hover {
      background-color: #c4e5f2;
    }
  }
`;

const ContainerRight = styled.div`
  width: 35%;
  background-color: #c4e5f2;
  position: relative;

  @media (max-width: 1249px) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
  }
`;

const ContainerRightImage = styled.img`
  width: 600px;
  height: auto;
  border-radius: 3rem;
  position: relative;
  top: 15%;
  left: -25%;
  animation: ${slideInFromBottom} 0.75s ease-out forwards;

  @media (max-width: 1399px) {
    width: 500px;
    height: auto;
    left: -20%;
  }

  @media (max-width: 1249px) {
    position: static;
    width: 80%;
    height: auto;
  }
`;

const FirstSection = ({ scrollToContactForm }) => {
  return (
    <Container>
      <ContainerLeft>
        <ContainerLeftContent>
          <h1>
            Performance
            <br />
            Marketing...
            <br />
            Guaranteed
          </h1>
          <p>
            Looking for New Customers? Our custom built online and offline
            marketing campaigns reach....
          </p>
          <button
            onClick={() => {
              trackClick("button", "Contact Us Button");
              scrollToContactForm();
            }}
          >
            Contact Us
          </button>
        </ContainerLeftContent>
      </ContainerLeft>
      <ContainerRight>
        <ContainerRightImage
          src="https://cdn.prod.website-files.com/65272808fd070a45d9fa34ba/6527364c136d755fb5451c92_adsf.png"
          alt="Generic Image"
        />
      </ContainerRight>
    </Container>
  );
};

export default FirstSection;
