import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useEffect, useState } from "react";
import axios from "axios";
import { InputMask } from "@react-input/mask";
import { trackClick } from "../../utils";

const Container = styled.section`
  position: relative;
  background-color: #003566;
  padding: 4rem 2rem;

  @media (max-width: 1249px) {
    padding: 2rem;
  }

  @media (max-width: 768px) {
    padding: 2rem 1rem;
    min-height: fit-content;
  }
`;

const Heading = styled.h2`
  color: white;
  font-size: 3.5rem;
  text-align: center;
  font-weight: 300;
  width: 100%;
  font-family: "DM Serif Display", serif;

  @media (max-width: 1249px) {
    font-size: 2.5rem;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const FormContainer = styled.div`
  width: 98%;
  margin: 2rem auto;
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
  justify-content: space-between;
  background-color: white;
  padding: 4rem 16rem;

  @media (max-width: 1249px) {
    padding: 2rem;
  }
`;

const LeftSide = styled.div`
  flex: 2;
  min-width: 520px;

  @media (max-width: 768px) {
    min-width: 100%;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
`;

const SuccessText = styled.p`
  background-color: #ffd60a;
  padding: 1.6rem;
  font-size: 1.1rem;
  border-radius: 12px;

  @media (max-width: 768px) {
    padding: 1rem;
    font-size: 0.9rem;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  input {
    width: 90%;
    padding: 0.75rem;
    border: none;
    outline: none;
    border-bottom: 1px solid #003566;
  }

  textarea {
    width: 90%;
    padding: 0.75rem;
    border: none;
    outline: none;
    border-bottom: 1px solid #003566;
  }

  p {
    font-weight: 500;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const Button = styled.button`
  background-color: #ffd60a;
  border: none;
  outline: none;
  border-radius: 100rem;
  padding: 1rem 1.8rem;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  width: 170px;
  margin-top: 2rem;
  color: black;

  &:hover {
    background-color: #c4e5f2;
  }
`;

const RightSide = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  min-width: 420px;

  @media (max-width: 1249px) {
    gap: 1rem;
    min-width: 100%;
  }

  svg {
    width: 36px;
    fill: #003566;
    cursor: pointer;
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  a {
    color: #003566;
  }
`;

const FooterContainer = styled.div`
  width: 80%;
  margin: 2rem auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;

  img {
    width: 180px;
  }

  p {
    color: white;
    font-size: 1.05rem;
  }
`;

const Footer = ({ forwardRef }) => {
  const location = useLocation();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    tc_accepted: false,
    "g-recaptcha-response": "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  function clearPhoneNumberFormatting(phoneNumber) {
    // Remove all non-digit characters
    return phoneNumber.replace(/\D/g, "");
  }

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value.trimStart(),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.tc_accepted) {
      setError("You must accept the Terms of Service");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/mrg/contact`,
        { ...formData, phone: clearPhoneNumberFormatting(formData.phone) }
      );
      console.log("Response:", response.data);
      setSuccess(true);
    } catch (err) {
      console.error("Error:", err);
      setError("Something went wrong. Please try again.");
    } finally {
      setLoading(false);
      // New POST request
      const trackingData = {
        form_data: {
          name: formData.name,
          email: formData.email,
        },
        url: "https://mrginc.com/",
      };

      fetch("https://easy-panel-api.lim.bz/api/tracking/form", {
        method: "POST",
        headers: {
          "SITE-TOKEN": "T1IBzVwQ8dKuZPU4NvZqLA913hc84I6y",
          "Content-Type": "application/json", // Add this
        },
        body: JSON.stringify(trackingData),
      })
        .then((trackingResponse) => trackingResponse.json())
        .then((trackingData) => {
          console.log("Tracking request success:", trackingData);
        })
        .catch((trackingError) => {
          console.error("Tracking request error:", trackingError);
        });
    }
  };
  const navigate = useNavigate();
  // const location = useLocation();

  useEffect(() => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute("6LePGKwqAAAAAMGOL1PtMEQoIc6qhuyvjLw_JgOA", {
          action: "submit",
        })
        .then((token) => {
          setFormData((prevFormData) => ({
            ...prevFormData,
            "g-recaptcha-response": token,
          }));
        });
    });
  }, []);

  const footerHiddenRoutes = [
    "/terms-conditions",
    "/privacy-policy",
    "/do-not-sell-my-personal-information",
  ];

  const isFooterHidden = footerHiddenRoutes.includes(location.pathname);
  return (
    <Container>
      {!isFooterHidden && (
        <>
          <Heading>
            Get In Touch and Let's
            <br />
            Take Your Marketing to
            <br />
            the Next Level
          </Heading>

          <FormContainer ref={forwardRef}>
            {success ? (
              <LeftSide
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SuccessText>
                  Thank you! Your submission has been received!
                </SuccessText>
              </LeftSide>
            ) : (
              <LeftSide>
                <form onSubmit={handleSubmit}>
                  <InputContainer>
                    <p>What's your name?</p>
                    <input
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Your full name *"
                      required
                    />
                  </InputContainer>
                  <InputContainer>
                    <p>What's your email address?</p>
                    <input
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Hello@company.com *"
                      required
                    />
                  </InputContainer>
                  <InputContainer>
                    <p>What's your phone number?</p>
                    <InputMask
                      name="phone"
                      required
                      placeholder="Phone Number*"
                      onChange={handleChange}
                      mask="+1 (___) ___-____"
                      replacement={{ _: /\d/ }}
                    />
                  </InputContainer>
                  <InputContainer>
                    <p>Message</p>
                    <textarea
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      placeholder="Type your message.."
                    />
                  </InputContainer>

                  <CheckboxContainer>
                    <input
                      type="checkbox"
                      id="checkbox"
                      name="tc_accepted"
                      checked={formData.tc_accepted}
                      onChange={handleChange}
                    />
                    <label htmlFor="checkbox">
                      I accept the Terms of Service
                    </label>
                  </CheckboxContainer>

                  <Button disabled={loading}>
                    {loading ? "Submitting..." : "Submit"}
                  </Button>
                  {error && <p style={{ color: "red" }}>{error}</p>}
                </form>
              </LeftSide>
            )}
            <RightSide>
              <div>
                <p style={{ fontWeight: 600, fontSize: "1.1rem" }}>Contact</p>
                <a
                  style={{ color: "#003566" }}
                  href="mailto:info@mrginc.com"
                  target="_blank"
                  rel="noreferer"
                >
                  info@mrginc.com
                </a>
                <a style={{ color: "#003566" }} href="tel:2396313553">
                  239-631-3553
                </a>
              </div>

              <div>
                <p style={{ fontWeight: 600, fontSize: "1.1rem" }}>Address</p>
                <p>2430 Vanderbilt Beach Rd. Ste. 108-558</p>
                <p>Naples, FL 34109</p>
              </div>

              <div>
                <a
                  href="https://www.linkedin.com/company/media-resource-group/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
                  </svg>
                </a>
              </div>
            </RightSide>
          </FormContainer>
        </>
      )}

      <FooterContainer>
        <img
          src="https://cdn.prod.website-files.com/65272808fd070a45d9fa34ba/6527300119ba7da7538c1d6f_White%20Transparent%20Logo-p-500.png"
          alt="MRG Logo"
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            flexWrap: "wrap",
            minWidth: "320px",
          }}
        >
          <p
            style={{ cursor: "pointer" }}
            onClick={() => {
              trackClick("navigation", "About");
              navigate("/about");
            }}
          >
            About
          </p>
          <p style={{ cursor: "pointer" }}>Services</p>
          <p style={{ cursor: "pointer" }}>Products</p>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            flexWrap: "wrap",
            minWidth: "320px",
          }}
        >
          <a
            href="https://mrgstaging.com/policy/mrgtandc.html"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "white", cursor: "pointer" }}
            onClick={() =>
              trackClick("Terms Conditions", "Terms And Conditions")
            }
          >
            Terms And Conditions
          </a>
          <a
            href="https://mrgstaging.com/policy/mrgprivacy.html"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "white", cursor: "pointer" }}
            onClick={() => trackClick("Privacy Policy", "Privacy Policy")}
          >
            Privacy Policy
          </a>
          <a
            href="/do-not-sell-my-personal-information"
            style={{ color: "white", cursor: "pointer" }}
            onClick={() =>
              trackClick("Do Not Sell Info", "Do Not Sell My Info")
            }
          >
            Do Not Sell My Info
          </a>
        </div>
      </FooterContainer>
    </Container>
  );
};

export default Footer;
