import EightSection from "../components/home/EightSection";
import FifthSection from "../components/home/FifthSection";
import FirstSection from "../components/home/FirstSection";
import FourthSection from "../components/home/FourthSection";
import SecondSection from "../components/home/SecondSection";
import SeventhSection from "../components/home/SeventhSection";
import SixthSection from "../components/home/SixthSection";
import ThirdSection from "../components/home/ThirdSection";

const Home = ({scrollToContactForm}) => {
    return(
        <>
            <FirstSection scrollToContactForm={scrollToContactForm}/>
            <SecondSection scrollToContactForm={scrollToContactForm}/>
            <ThirdSection />
            <FourthSection scrollToContactForm={scrollToContactForm}/>
            <FifthSection scrollToContactForm={scrollToContactForm}/>
            <SixthSection scrollToContactForm={scrollToContactForm}/>
            <SeventhSection scrollToContactForm={scrollToContactForm}/>
            <EightSection />
        </>
    )
}

export default Home;