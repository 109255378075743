import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { trackClick } from "../../utils";

const slideInFromTop = keyframes`
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
`;

const slideOutToTop = keyframes`
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-100%);
    }
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 10%;
  background-color: white;
  z-index: 999;

  @media (max-width: 1249px) {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const LeftNav = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;

  p {
    color: #003566;
    cursor: pointer;
  }
`;

const Logo = styled.img`
  width: 120px;
  cursor: pointer;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 2rem;

  @media (max-width: 1249px) {
    display: none;
  }
`;

const RightNav = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  button {
    background-color: #ffd60a;
    border: none;
    outline: none;
    border-radius: 100rem;
    padding: 1rem 1.8rem;
    font-size: 0.95rem;
    font-weight: 500;
    cursor: pointer;
    width: 170px;
    color: black;

    @media (max-width: 768px) {
      padding: 0.75rem;
      font-size: 0.8rem;
      width: 120px;
    }
  }

  svg {
    width: 24px;
    display: none;
    cursor: pointer;

    @media (max-width: 1249px) {
      display: block;
    }
  }
`;

const MobileMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  z-index: 1000;
  animation: ${({ isOpen }) => (isOpen ? slideInFromTop : slideOutToTop)} 0.3s
    forwards;

  p {
    color: #003566;
    font-size: 1.5rem;
    cursor: pointer;

    &:hover {
      color: #ffd60a;
    }
  }

  button {
    background-color: #ffd60a;
    border: none;
    outline: none;
    border-radius: 100rem;
    padding: 1rem 1.8rem;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;

    &:hover {
      background-color: #c4e5f2;
    }
  }
`;

const MobileNav = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  gap: 2rem;

  svg {
    fill: #003566;
    width: 24px;
    cursor: pointer;
  }
`;

const Nav = ({ scrollToContactForm, isMenuOpen, toggleMenu }) => {
  const navigate = useNavigate();

  const navigateAbout = () => {
    if (isMenuOpen) toggleMenu();
    return navigate("/about");
  };

  return (
    <>
      <NavContainer>
        <LeftNav>
          <Logo
            onClick={() => navigate("/")}
            src="https://cdn.prod.website-files.com/65272808fd070a45d9fa34ba/65272d7afd070a45d9033eb0_MRG%20White%20Transparent-p-500.png"
            alt="Logo"
          />
          <NavLinks>
            <p onClick={navigateAbout}>About</p>
            <p>Services</p>
            <p>Products</p>
          </NavLinks>
        </LeftNav>

        <RightNav>
          <button
            onClick={() => {
              trackClick("button", "Contact Us Button");
              scrollToContactForm();
            }}
          >
            Contact Us
          </button>
          <svg
            onClick={toggleMenu}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path d="M0 96C0 78.3 14.3 64 32 64l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 128C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32l384 0c17.7 0 32 14.3 32 32s-14.3 32 32 32L32 288c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32L32 448c-17.7 0-32-14.3-32-32s14.3-32 32-32l384 0c17.7 0 32 14.3 32 32z" />
          </svg>
        </RightNav>
      </NavContainer>

      {isMenuOpen && (
        <MobileMenu isOpen={isMenuOpen}>
          <MobileNav>
            <Logo
              onClick={() => navigate("/")}
              src="https://cdn.prod.website-files.com/65272808fd070a45d9fa34ba/65272d7afd070a45d9033eb0_MRG%20White%20Transparent-p-500.png"
              alt="Logo"
            />
            <svg
              onClick={toggleMenu}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path d="M0 96C0 78.3 14.3 64 32 64l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 128C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32l384 0c17.7 0 32 14.3 32 32s-14.3 32 32 32L32 288c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32L32 448c-17.7 0-32-14.3-32-32s14.3-32 32-32l384 0c17.7 0 32 14.3 32 32z" />
            </svg>
          </MobileNav>
          <p onClick={navigateAbout}>About</p>
          <p onClick={toggleMenu}>Services</p>
          <p onClick={toggleMenu}>Products</p>
          <button onClick={toggleMenu}>Get In Touch</button>
        </MobileMenu>
      )}
    </>
  );
};

export default Nav;
