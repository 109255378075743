import styled, { keyframes } from "styled-components";
import { useEffect, useRef } from "react";

const Container = styled.section`
  position: relative;
  padding: 4rem 2rem;

  @media (max-width: 1249px) {
    padding: 2rem 1rem;
  }
`;

const Heading = styled.h2`
  color: #003566;
  font-size: 3.5rem;
  text-align: center;
  font-weight: 500;
  width: 100%;
  font-family: "DM Serif Display", serif;

  @media (max-width: 1249px) {
    font-size: 2.5rem;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Description = styled.p`
  color: #003566;
  font-size: 1.6rem;
  margin-top: 2rem;
  text-align: center;
  font-weight: 300;
  width: 100%;
  line-height: 2.2rem;

  @media (max-width: 1249px) {
    font-size: 1.2rem;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 1.4rem;
  }
`;

const TilesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 4rem;
  width: 75%;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    width: 98%;
  }
`;

const Tile = styled.div`
  background-color: #fafafa;
  border-radius: 1rem;
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  flex: 1;
  min-width: 600px;

  @media (max-width: 768px) {
    min-width: 100%;
    padding: 1.5rem;
  }

  img {
    width: 48px;
  }

  h3 {
    color: #003566;
    font-size: 2.2rem;
    font-weight: 500;
    width: 100%;

    @media (max-width: 1249px) {
      font-size: 1.6rem;
    }

    @media (max-width: 768px) {
      font-size: 1.2rem;
    }
  }

  p {
    color: #003566;
    font-size: 1rem;
    font-weight: 300;
    width: 100%;
    line-height: 1.5rem;

    @media (max-width: 768px) {
      font-size: 0.9rem;
      line-height: 1.4rem;
    }
  }
`;

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const AnimatedTile = styled(Tile)`
  opacity: 0;
  transform: translateY(60px);
  transition: opacity 0.4s, transform 0.4s;

  &.visible {
    opacity: 1;
    transform: translateY(0);
    animation: ${fadeInUp} 0.6s ease-in-out;
  }
`;

const tilesData = [
  {
    img: "https://cdn.prod.website-files.com/65272808fd070a45d9fa34ba/652c68b129f6f7d9d2a1820c_Media%20Resource%20Group%20Buying%20Planning%20Analysis.png",
    title: "Media Buying, Planning & Analysis",
    text: "Drive cost-effective results with our pay-per-lead digital and offline marketing campaigns. We ensure you only pay when you get quality leads, optimizing your online presence and increasing conversion rates.",
  },
  {
    img: "https://cdn.prod.website-files.com/65272808fd070a45d9fa34ba/652c68b079cbf4ed07ec413d_Media%20Resource%20Group%20Custom%20Media%20Vehicles-p-500.png",
    title: "Custom Media Vehicles",
    text: "Take advantage of our print production procurement services to develop the most efficient printing format for your needs. We offer convenient, quality and cost-effective resources for your complete project management.",
  },
  {
    img: "https://cdn.prod.website-files.com/65272808fd070a45d9fa34ba/652c68b0b4f9def31aec0382_Media%20Resource%20Group%20Media%20Program%20Management-p-500.png",
    title: "Media Program Management",
    text: "We help brands create sales revenues by placing third-party partnership offers into their customer communications. See products.",
  },
  {
    img: "https://cdn.prod.website-files.com/65272808fd070a45d9fa34ba/652c68b087d36e6c666c0386_Media%20Resource%20Group%20Print%20Production.png",
    title: "Print Production",
    text: "Take advantage of our print production procurement services to develop the most efficient printing format for your needs. We offer convenient, quality and cost-effective resources for your complete project management.",
  },
  {
    img: "https://cdn.prod.website-files.com/65272808fd070a45d9fa34ba/652c68b0e3e8f973375f6bfa_Media%20Resource%20Group%20Creative%20Design.png",
    title: "Creative",
    text: "From direct mailings to websites and social media, our curated creative resources have extensive experience in designing a variety of formats that achieve maximum results. We know how to produce creative that engages audiences and encourages positive perceptions and actions.",
  },
  {
    img: "https://cdn.prod.website-files.com/65272808fd070a45d9fa34ba/652c68b1b4f9def31aec0387_Media%20Resource%20Group%20Digital%20Marketing-p-500.png",
    title: "Digital Marketing",
    text: "Our team of experts leverages the latest tools and trends to boost your online presence, engage your audience, and optimize conversions. Let us be your trusted partner to deliver growth, visibility, and success for your brand.",
  },
];

const ThirdSection = () => {
  const tilesRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry, index) => {
          if (entry.isIntersecting) {
            // Apply the visible class with staggered timing in pairs
            setTimeout(() => {
              entry.target.classList.add("visible");
            }, Math.floor(index / 2) * 300); // Delay increases every 2 tiles
          }
        });
      },
      { threshold: 0.3 }
    );

    tilesRef.current.forEach((tile) => {
      if (tile) observer.observe(tile);
    });

    return () => observer.disconnect();
  }, []);
  return (
    <Container id="services">
      <Heading>
        Six Core Services to <br /> Drive Performance.
      </Heading>
      <Description>
        From data-driven insights to full media program <br /> management, we
        offer a comprehensive suite of <br /> services designed to elevate your
        brand's performance.
      </Description>
      <TilesContainer>
        {tilesData.map((tile, index) => (
          <AnimatedTile
            key={index}
            ref={(el) => (tilesRef.current[index] = el)}
          >
            <img src={tile.img} alt={tile.title} />
            <h3>{tile.title}</h3>
            <p>{tile.text}</p>
          </AnimatedTile>
        ))}
      </TilesContainer>
    </Container>
  );
};

export default ThirdSection;
