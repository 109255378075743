import { useRef, useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Nav from "./components/nav/Nav";
import Footer from "./components/footer/Footer";
import SellInfo from "./pages/SellInfo";
import axios from "axios";

function Main() {
  const contactFormRef = useRef(null);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const scrollToContactForm = () => {
    contactFormRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  function getOrCreateUniqueID() {
    let uniqueID = localStorage.getItem("unique_user_id");

    if (!uniqueID) {
      uniqueID = crypto.randomUUID(); // Generates a unique ID
      localStorage.setItem("unique_user_id", uniqueID);
    }

    return uniqueID;
  }

  useEffect(() => {
    async function fetchUserIPAndTrack() {
      try {
        // Fetch user's IP address
        const ipResponse = await axios.get(
          "https://api64.ipify.org?format=json"
        );
        const userIP = ipResponse.data.ip;

        // Get unique user ID
        const uniqueID = getOrCreateUniqueID();

        // Prepare page view data
        const pageViewData = {
          url: window.location.href,
          user_agent: navigator.userAgent,
          referrer: document.referrer,
          unique_id: uniqueID,
          ip_address: userIP,
        };

        // Send tracking data
        const response = await axios.post(
          "https://easy-panel-api.lim.bz/api/tracking/pageview",
          pageViewData,
          {
            headers: {
              "Content-Type": "application/json",
              "SITE-TOKEN": "T1IBzVwQ8dKuZPU4NvZqLA913hc84I6y",
            },
          }
        );

        console.log("Pageview tracking successful:", response.data);
      } catch (error) {
        console.error("Error tracking pageview:", error.message);
      }
    }

    fetchUserIPAndTrack();
  }, []);

  return (
    <>
      <Nav
        isMenuOpen={isMenuOpen}
        toggleMenu={toggleMenu}
        scrollToContactForm={scrollToContactForm}
      />
      <Routes>
        <Route
          path="/"
          element={<Home scrollToContactForm={scrollToContactForm} />}
        />
        <Route
          path="/about"
          element={<About scrollToContactForm={scrollToContactForm} />}
        />
        {/* <Route path="/terms-conditions" element={<Terms />} />
        <Route path="/privacy-policy" element={<Privacy />} /> */}
        <Route
          path="/do-not-sell-my-personal-information"
          element={<SellInfo forwardRef={contactFormRef} />}
        />
      </Routes>
      <Footer forwardRef={contactFormRef} />
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Main />
    </BrowserRouter>
  );
}

export default App;
