import React, { useRef, useState, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";
import { trackClick } from "../../utils";

const slideInFromBottom = keyframes`
  0% {
    transform: translateY(50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideInAndGrow = keyframes`
  0% {
    transform: translateX(100%) scale(0.5);
    opacity: 0;
  }
  50% {
    transform: translateX(0) scale(0.75);
    opacity: 1;
  }
  100% {
    transform: translateX(0) scale(1);
  }
`;

const Container = styled.section`
  position: relative;
  background-color: white;
`;

const ContentSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AbsoluteBackgrounds = styled.div`
  position: absolute;
  min-height: 100%;
  width: 100%;
  display: flex;
`;

const BackgrdoundLeft = styled.div`
  flex: 2;
  background-image: url("/bgsecond.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const BackgroundRight = styled.div`
  flex: 3;
`;

const UpperContent = styled.div`
  width: 100%;
  z-index: 999;
  padding: 2rem;

  @media (max-width: 1249px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const Heading = styled.h2`
  color: #003566;
  font-size: 3.5rem;
  text-align: center;
  font-weight: 300;
  font-family: "DM Serif Display", serif;
  width: 100%;

  @media (max-width: 1249px) {
    font-size: 2.5rem;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Description = styled.p`
  color: #003566;
  font-size: 1.6rem;
  margin-top: 2rem;
  text-align: center;
  font-weight: 300;
  width: 100%;
  line-height: 2.2rem;

  @media (max-width: 1249px) {
    font-size: 1.2rem;
  }

  @media (max-width: 768px) {
    font-size: 1.1rem;
    line-height: 1.4rem;
  }
`;

const ImageAndList = styled.div`
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 4rem;

  @media (max-width: 1249px) {
    gap: 2rem;
  }

  svg {
    width: 24px;
    fill: #ffd60a;
  }
`;

const Image = styled.img`
  width: 30%;
  border-radius: 3rem;
  ${({ isVisible }) =>
    isVisible &&
    css`
      animation: ${slideInFromBottom} 1s ease-out forwards;
    `};

  @media (max-width: 1249px) {
    min-width: 460px;
    order: 2;
  }

  @media (max-width: 768px) {
    min-width: 95%;
    width: 95%;
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
`;

const ListDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;

  ${({ isVisible }) =>
    isVisible &&
    css`
      animation: ${slideInAndGrow} 1s ease-out forwards;
    `}
`;

const ListText = styled.p`
  font-size: 1.4rem;
  font-weight: 500;
  color: #003566;

  @media (max-width: 1249px) {
    font-size: 1.2rem;
  }
`;

const ListSmallerText = styled.p`
  color: #003566;
  font-size: 1rem;

  @media (max-width: 1249px) {
    font-size: 0.9rem;
  }
`;

const Button = styled.button`
  background-color: #ffd60a;
  border: none;
  outline: none;
  border-radius: 100rem;
  padding: 1rem 1.8rem;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  width: 170px;
  margin-top: 4rem;

  @media (max-width: 768px) {
    padding: 0.75rem;
    font-size: 0.8rem;
    width: 120px;
    margin-top: 1rem;
  }

  &:hover {
    background-color: #c4e5f2;
  }
`;

const FifthSection = ({ scrollToContactForm }) => {
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <Container ref={containerRef}>
      <ContentSection>
        <AbsoluteBackgrounds>
          <BackgrdoundLeft></BackgrdoundLeft>
          <BackgroundRight></BackgroundRight>
        </AbsoluteBackgrounds>
        <UpperContent>
          <Heading>
            Expert Marketing
            <br />
            Services, Tailored to
            <br />
            Your Needs.
          </Heading>
          <Description>
            Working as an extension of your company, we identify
            <br />
            your needs and develop an actionable, dynamic plan to
            <br />
            deliver unparalleled success.
          </Description>

          <ImageAndList>
            <Image
              isVisible={isVisible}
              src="https://cdn.prod.website-files.com/65272808fd070a45d9fa34ba/65272808fd070a45d9fa358a_pexels-tony-schnagl-5588207.jpg"
              alt="A Man Typing On Laptop"
            />

            <ListContainer>
              <ListDiv isVisible={isVisible}>
                <ListText>Client Success Focus</ListText>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "1rem",
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                  </svg>
                  <ListSmallerText>
                    Your success is our priority. We're dedicated to achieving
                    your business goals, ensuring
                    <br />
                    that your growth and satisfaction are at the forefront of
                    everything we do.
                  </ListSmallerText>
                </div>
              </ListDiv>

              <ListDiv isVisible={isVisible}>
                <ListText>Long-Term Partnership</ListText>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "1rem",
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                  </svg>
                  <ListSmallerText style={{ color: "#003566" }}>
                    Our team of industry experts are with you every step of the
                    way as proactive partners with
                    <br />
                    shared goals and values.
                  </ListSmallerText>
                </div>
              </ListDiv>

              <ListDiv isVisible={isVisible}>
                <ListText>Continous Learning</ListText>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "1rem",
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                  </svg>
                  <ListSmallerText style={{ color: "#003566" }}>
                    Our team is at the forefront of industry trends and
                    technologies to keep your strategies
                    <br />
                    cutting-edge.
                  </ListSmallerText>
                </div>
              </ListDiv>

              <Button
                onClick={() => {
                  trackClick("button", "Contact Us Button");
                  scrollToContactForm();
                }}
              >
                Contact Us
              </Button>
            </ListContainer>
          </ImageAndList>
        </UpperContent>
      </ContentSection>
    </Container>
  );
};

export default FifthSection;
