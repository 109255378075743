import styled from "styled-components";
import { trackClick } from "../../utils";

const Container = styled.section`
  position: relative;
  background-color: #003566;
  padding: 4rem 2rem;

  @media (max-width: 1249px) {
    padding: 2rem;
  }

  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

const Heading = styled.h2`
  color: white;
  font-size: 3.5rem;
  text-align: center;
  font-weight: 300;
  width: 100%;
  font-family: "DM Serif Display", serif;

  @media (max-width: 1249px) {
    font-size: 2.5rem;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Description = styled.p`
  color: white;
  font-size: 1.6rem;
  margin-top: 2rem;
  text-align: center;
  font-weight: 300;
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  line-height: 2.2rem;

  @media (max-width: 1249px) {
    font-size: 1.2rem;
    width: 90%;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 1.4rem;
    width: 100%;
  }
`;

const ProductsContainer = styled.div`
  width: 70%;
  margin: 2rem auto;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  flex-wrap: wrap;

  @media (max-width: 1249px) {
    width: 90%;
  }
`;

const Product = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  width: 380px;
  height: 260px;
  padding: 2rem;
  box-sizing: border-box;

  img {
    width: 160px;
    flex-shrink: 0;
  }

  button {
    border: 1px solid #c4e5f2;
    border-radius: 3rem;
    background-color: transparent;
    padding: 0.75rem 1.5rem;
    font-weight: 600;
    color: #003566;
    cursor: pointer;
    flex-shrink: 0;
    margin-top: auto;
  }

  a {
    text-decoration: none;
    color: #003566;
  }
`;

const Button = styled.button`
  background-color: #ffd60a;
  border: none;
  outline: none;
  border-radius: 100rem;
  padding: 1rem 1.8rem;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  width: 170px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 2rem;
  color: black;

  &:hover {
    background-color: #c4e5f2;
  }
`;

const SeventhSection = ({ scrollToContactForm }) => {
  return (
    <Container>
      <Heading>
        A Variety of Managed
        <br />
        Products...
      </Heading>
      <Description>
        Our management division represents media vehicles from the following
        brands, offering unique lifestyle audiences for national advertisers.
      </Description>

      <ProductsContainer>
        <Product>
          <img
            style={{ width: "240px" }}
            src="https://cdn.prod.website-files.com/65272808fd070a45d9fa34ba/652f11d908cb834b89a29b5f_Screenshot%202023-10-17%20at%205.59.30%20PM.png"
            alt="Welcome Home Logo"
          />
          <button>
            <a href="/welcome_home_pdf.pdf" target="_blank" rel="noreferrer">
              Learn More
            </a>
          </button>
        </Product>
        <Product>
          <img
            src="https://cdn.prod.website-files.com/65272808fd070a45d9fa34ba/652ec0366d221af01cd295dd_Credit%20Card%20Statement%20Program%20(4).png"
            alt="Kohl's Logo"
          />
          <button>
            <a href="/kohls_statement.pdf" target="_blank" rel="noreferrer">
              Learn More
            </a>
          </button>
        </Product>
        <Product>
          <img
            src="https://cdn.prod.website-files.com/65272808fd070a45d9fa34ba/652ec00d01944cd609c2857d_Credit%20Card%20Statement%20Program%20(3).png"
            alt="Kohl's Logo"
          />
          <button>
            <a href="/Kohls_pip.pdf" target="_blank" rel="noreferrer">
              Learn More
            </a>
          </button>
        </Product>
        <Product>
          <img
            src="https://cdn.prod.website-files.com/65272808fd070a45d9fa34ba/652b373e722f7ce55d6486e6_IMG_0996.png"
            alt="Home Chef Logo"
          />
          <button>
            <a href="/homeChef_pdf.pdf" target="_blank" rel="noreferrer">
              Learn More
            </a>
          </button>
        </Product>
        <Product>
          <img
            style={{ width: "140px" }}
            src="https://cdn.prod.website-files.com/65272808fd070a45d9fa34ba/652d6c71044bf283c6884eb1_TVweekly.jpeg"
            alt="Weekly TV Logo"
          />
          <button>
            <a href="/tv_weekly.pdf" target="_blank" rel="noreferrer">
              Learn More
            </a>
          </button>
        </Product>
        <Product>
          <img
            style={{ width: "120px" }}
            src="https://www.tvguidemagazine.com/blog/wp-content/themes/tvguide/images/logo.png"
            alt="TV Guide Logo"
          />
          <button>
            <a href="/tv_guide.pdf" target="_blank" rel="noreferrer">
              Learn More
            </a>
          </button>
        </Product>
      </ProductsContainer>

      <Button
        onClick={() => {
          trackClick("button", "Contact Us Button");
          scrollToContactForm();
        }}
      >
        Contact Us
      </Button>
    </Container>
  );
};

export default SeventhSection;
