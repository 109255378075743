export const trackClick = (type, element) => {
  const clickData = {
    type: type,
    element: element,
    url: window.location.href,
  };

  fetch("https://easy-panel-api.lim.bz/api/tracking/click", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "SITE-TOKEN": "T1IBzVwQ8dKuZPU4NvZqLA913hc84I6y",
    },
    body: JSON.stringify(clickData),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok " + response.statusText);
      }
      return response.json();
    })
    .then((data) => {
      console.log("Click tracking successful:", data);
    })
    .catch((error) => {
      console.error("Error tracking click:", error);
    });
};

// trackClick("button", "Disclaimer decline button");
