import FirstSection from "../components/about/FirstSection";
import FourthSection from "../components/about/FourthSection";
import SecondSection from "../components/about/SecondSection";
import ThirdSection from "../components/about/ThirdSection";
import { useEffect } from "react";

const About = ({ scrollToContactForm }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <FirstSection scrollToContactForm={scrollToContactForm} />
      <SecondSection scrollToContactForm={scrollToContactForm} />
      <ThirdSection scrollToContactForm={scrollToContactForm} />
      <FourthSection />
    </>
  );
};

export default About;
