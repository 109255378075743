import styled from "styled-components";
import { trackClick } from "../../utils";

const Container = styled.section`
  position: relative;
  padding: 4rem 2rem;
  background-color: #f5fbff;

  @media (max-width: 1249px) {
    padding: 2rem;
  }

  @media (max-width: 768px) {
    padding: 2rem 1rem;
    min-height: fit-content;
  }
`;

const Heading = styled.h2`
  color: #003566;
  font-size: 3.5rem;
  text-align: center;
  font-weight: 300;
  width: 100%;
  font-family: "DM Serif Display", serif;

  @media (max-width: 1249px) {
    font-size: 2.5rem;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const StepByStepContainer = styled.div`
  margin-top: 4rem;
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 1249px) {
    width: 60%;
  }

  @media (max-width: 768px) {
    width: 98%;
  }
`;

const OneStepContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  width: 100%;
  height: fit-content;
  margin-top: 2rem;

  @media (max-width: 768px) {
    gap: 1rem;
  }

  img {
    width: 36px;

    @media (max-width: 1249px) {
      width: 24px;
    }
  }
`;

const OneStepHeading = styled.p`
  color: #003566;
  font-size: 2rem;
  text-align: center;
  font-weight: 500;
  width: 100%;
  text-align: left;

  @media (max-width: 1249px) {
    font-size: 1.6rem;
  }

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const OneStepDescription = styled.p`
  color: #003566;
  font-size: 1rem;
  margin-top: 1rem;
  text-align: center;
  font-weight: 300;
  width: 100%;
  line-height: 1.6rem;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 0.9rem;
    line-height: 1.2rem;
    margin-top: 0.5rem;
  }
`;

const Button = styled.button`
  background-color: #ffd60a;
  border: none;
  outline: none;
  border-radius: 100rem;
  padding: 1rem 1.8rem;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  width: 170px;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  color: black;

  &:hover {
    background-color: #c4e5f2;
  }
`;

const ProgressDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 10%;
  gap: 1rem;
`;

const HorizontalLine = styled.div`
  height: 120px;
  width: 1px;
  border: 1px solid lightgray;
`;

const SixthSection = ({ scrollToContactForm }) => {
  return (
    <Container>
      <Heading>
        Your Success with a<br />
        Strategic 4-Step Marketing
        <br />
        Process
      </Heading>
      <StepByStepContainer>
        <OneStepContainer>
          <ProgressDiv>
            <img
              src="https://cdn.prod.website-files.com/65272808fd070a45d9fa34ba/65272808fd070a45d9fa35ba_process-step1.svg"
              alt="Quarter Yellow Circle"
            />
            <HorizontalLine />
          </ProgressDiv>

          <div style={{ width: "90%" }}>
            <OneStepHeading>Research and Analysis</OneStepHeading>
            <OneStepDescription>
              Thoroughly research and analyze the relevant market, industry
              trends, target audience,
              <br />
              and competitors to gain valuable insights and inform strategic
              decisions.
            </OneStepDescription>
          </div>
        </OneStepContainer>

        <OneStepContainer>
          <ProgressDiv>
            <img
              src="https://cdn.prod.website-files.com/65272808fd070a45d9fa34ba/65272808fd070a45d9fa35b9_process-step2.svg"
              alt="Quarter Yellow Circle"
            />
            <HorizontalLine />
          </ProgressDiv>

          <div style={{ width: "90%" }}>
            <OneStepHeading>
              Discovery, Planning, and Strategy Development
            </OneStepHeading>
            <OneStepDescription>
              We dive deep into your organization to understanding your
              strategic goals and KPIs.
              <br />
              Based on our research findings, we develop a comprehensive plan
              and strategy that
              <br />
              aligns perfectly with your objectives.
            </OneStepDescription>
          </div>
        </OneStepContainer>

        <OneStepContainer>
          <ProgressDiv>
            <img
              src="https://cdn.prod.website-files.com/65272808fd070a45d9fa34ba/65272808fd070a45d9fa35bb_process-step3.svg"
              alt="Quarter Yellow Circle"
            />
            <HorizontalLine />
          </ProgressDiv>

          <div style={{ width: "90%" }}>
            <OneStepHeading>Implementation and Execution</OneStepHeading>
            <OneStepDescription>
              Execute the planned strategies and tactics, ensuring proper
              implementation of
              <br />
              marketing campaigns, initiatives, and activities. This involves
              coordinating resources,
              <br />
              managing timelines, and monitoring progress throughout the
              execution phase.
            </OneStepDescription>
          </div>
        </OneStepContainer>

        <OneStepContainer>
          <ProgressDiv>
            <img
              src="https://cdn.prod.website-files.com/65272808fd070a45d9fa34ba/65272808fd070a45d9fa35b8_process-step4.svg"
              alt="Quarter Yellow Circle"
            />
          </ProgressDiv>

          <div style={{ width: "90%" }}>
            <OneStepHeading>Evaluation and Optimization</OneStepHeading>
            <OneStepDescription>
              Regularly evaluate the performance and effectiveness of the
              implemented strategies.
              <br />
              Measure key performance indicators (KPIs), gather data, and
              analyze results to identify
              <br />
              areas for improvement. Optimize the strategies and tactics based
              on the findings to
              <br />
              maximize success and achieve the desired outcomes.
            </OneStepDescription>
          </div>
        </OneStepContainer>

        <Button
          onClick={() => {
            trackClick("button", "Contact Us Button");
            scrollToContactForm();
          }}
        >
          Contact Us
        </Button>
      </StepByStepContainer>
    </Container>
  );
};

export default SixthSection;
