import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useEffect, useState } from "react";
import axios from "axios";
import { InputMask } from "@react-input/mask";

const Container = styled.section`
  padding: 4rem 2rem;
  width: 100%;
  max-width: 96rem;
  margin: 0 auto;

  @media (max-width: 1249px) {
    padding: 2rem 1rem;
  }
`;

const BigHeading = styled.h1`
  color: #003566;
  font-size: 4.5rem;
  font-weight: 500;
  font-family: "DM Serif Display", serif;

  @media (max-width: 1249px) {
    font-size: 3.5rem;
  }

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const Heading = styled.h2`
  color: #003566;
  font-size: 4rem;
  margin-top: 4rem;
  font-weight: 500;
  font-family: "DM Serif Display", serif;

  @media (max-width: 1249px) {
    font-size: 2.5rem;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Description = styled.div`
  color: #003566;
  font-size: 1rem;
  margin-top: 1rem;
  font-weight: 300;
  width: 100%;
  line-height: 1.8rem;

  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 1.4rem;
  }
`;

const FormContainer = styled.div`
  margin: 4rem auto;
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
  justify-content: space-between;
  background-color: white;
`;

const LeftSide = styled.div`
  flex: 2;
  min-width: 520px;

  @media (max-width: 768px) {
    min-width: 100%;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
`;

const SuccessText = styled.p`
  background-color: #ffd60a;
  padding: 1.6rem;
  font-size: 1.1rem;
  border-radius: 12px;

  @media (max-width: 768px) {
    padding: 1rem;
    font-size: 0.9rem;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  input {
    width: 90%;
    padding: 0.75rem;
    border: none;
    outline: none;
    border-bottom: 1px solid #003566;
  }

  textarea {
    width: 90%;
    padding: 0.75rem;
    border: none;
    outline: none;
    border-bottom: 1px solid #003566;
  }

  p {
    font-weight: 500;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const Button = styled.button`
  background-color: #ffd60a;
  border: none;
  outline: none;
  border-radius: 100rem;
  padding: 1rem 1.8rem;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  width: 170px;
  margin-top: 2rem;
  color: black;

  &:hover {
    background-color: #c4e5f2;
  }
`;

const RightSide = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  min-width: 420px;

  @media (max-width: 1249px) {
    gap: 1rem;
    min-width: 100%;
  }

  svg {
    width: 36px;
    fill: #003566;
    cursor: pointer;
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  a {
    color: #003566;
  }
`;

const SellInfo = ({ forwardRef }) => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    do_not_sell_info: false,
    delete_my_info: false,
    request_my_data: false,
    "g-recaptcha-response": "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value.trimStart(),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/mrg/delete-info`,
        formData
      );
      console.log("Response:", response.data);
      setSuccess(true);
    } catch (err) {
      console.error("Error:", err);
      setError("Something went wrong. Please try again.");
    } finally {
      setLoading(false);

      // New POST request
      const trackingData = {
        form_data: {
          name: `${formData.first_name} ${formData.last_name}`,
          email: formData.email,
        },
        url: "https://mrginc.com/do-not-sell-my-personal-information",
      };

      fetch("https://easy-panel-api.lim.bz/api/tracking/form", {
        method: "POST",
        headers: {
          "SITE-TOKEN": "T1IBzVwQ8dKuZPU4NvZqLA913hc84I6y",
          "Content-Type": "application/json", // Add this
        },
        body: JSON.stringify(trackingData),
      })
        .then((trackingResponse) => trackingResponse.json())
        .then((trackingData) => {
          console.log("Tracking request success:", trackingData);
        })
        .catch((trackingError) => {
          console.error("Tracking request error:", trackingError);
        });
    }
  };
  const navigate = useNavigate();
  // const location = useLocation();

  useEffect(() => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute("6LePGKwqAAAAAMGOL1PtMEQoIc6qhuyvjLw_JgOA", {
          action: "submit",
        })
        .then((token) => {
          setFormData((prevFormData) => ({
            ...prevFormData,
            "g-recaptcha-response": token,
          }));
        });
    });
  }, []);

  console.log(formData);
  return (
    <Container id="services">
      <BigHeading>
        Notice to California Residents: Your California Privacy Rights
      </BigHeading>
      <Heading>California Consumer Privacy Act ("CCPA")</Heading>

      <Description>
        If you are a California resident and our processing of personal
        information about you is subject to the CCPA, you have certain rights
        with respect to that information: <br />
        <br />
        <span style={{ fontWeight: "bold" }}>Access/Right to Know.</span> You
        have a right under the CCPA to request that we provide you with the
        following information:
        <br />
        <br />
        <ul style={{ marginLeft: "1.5rem" }}>
          <li>
            The categories and specific pieces of personal information we have
            collected about you in the last 12 months.
          </li>
          <li>
            The categories of sources from which we collected your personal
            information.
          </li>
          <li>
            Our purpose for collecting, using, or selling personal information.
          </li>
          <li>
            The categories of third parties with which we share personal
            information.
          </li>
          <li>
            The categories of personal information we have disclosed about you
            for a business purpose.
          </li>
          <li>
            The categories of personal information we have sold about you for
            each category of third party to which the personal information was
            sold.
          </li>
        </ul>
        <br />
        <span style={{ fontWeight: "bold" }}>Right to Delete.</span> You also
        have a right to request that we delete personal information under
        certain circumstances, subject to a number of exceptions.
        <br />
        <br />
        <span style={{ fontWeight: "bold" }}>Right to Opt-Out From Sales.</span>
        You also have a right to opt-out from future sales of personal
        information, which you can exercise by clicking on the “Do Not Sell My
        Personal Information” button on the home page of our website.
        <br />
        <br />
        <span style={{ fontWeight: "bold" }}>
          How to Exercise Your California CCPA Rights
        </span>
        <br />
        <br />
        You can exercise your CCPA rights by submitting your request(s) to us
        using the online form located below or by emailing us at{" "}
        <a href="mailto:info@mrginc.com" target="_blank" rel="noreferer">
          info@mrginc.com
        </a>
        .
        <br />
        <br />
        If you want to exercise more than one of your CCPA rights, please submit
        a separate request for each CCPA right. We will process your requests in
        the order we receive them.
        <br />
        <br />
        To complete your request we will need information from you so we can
        confirm you are a California resident, determine if we have personal
        information about you, and, for access and deletion requests, verify
        your identity. We may also need to contact you about your request.
        <br />
        <br />
        If we are unable to verify your identity and that you are a California
        resident, we may not honor your request. In addition, we may not delete
        your personal information if an exception under the CCPA applies.
        <br />
        <br />
        If your request comes from an authorized agent, we will require proof
        you have authorized the agent to act on your behalf, proof that the
        agent is registered with the California Secretary of State (for delete
        or access requests), and we may need you to verify your identity
        directly with us.
        <br />
        <br />
        You have a right to not be discriminated against for exercising rights
        set out in the CCPA.
      </Description>

      <Heading>Do Not Sell My Personal Information</Heading>

      <Description>
        By submitting your information below, you will be opting out of
        receiving future mailings from Media Resource Group LLC and will be
        added to our Do Not Mail list.
      </Description>

      <FormContainer ref={forwardRef}>
        {success ? (
          <LeftSide
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SuccessText>
              Thank you! Your submission has been received!
            </SuccessText>
          </LeftSide>
        ) : (
          <LeftSide>
            <form onSubmit={handleSubmit}>
              <InputContainer>
                <p>What's your first name?</p>
                <input
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleChange}
                  placeholder="First Name"
                  required
                />
              </InputContainer>

              <InputContainer>
                <p>What's your last name?</p>
                <input
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleChange}
                  placeholder="Last Name"
                  required
                />
              </InputContainer>

              <InputContainer>
                <p>What's your email address?</p>
                <input
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="hello@company.com"
                  required
                />
              </InputContainer>

              <InputContainer>
                <p>Street Address</p>
                <input
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  placeholder="Street Address"
                  required
                />
              </InputContainer>

              <InputContainer>
                <p>City</p>
                <input
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  placeholder="City"
                  required
                />
              </InputContainer>

              <InputContainer>
                <p>State</p>
                <input
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  placeholder="State"
                  required
                />
              </InputContainer>

              <InputContainer>
                <p>Zip</p>
                <input
                  name="zip"
                  value={formData.zip}
                  onChange={handleChange}
                  placeholder="Zip"
                  required
                />
              </InputContainer>

              <CheckboxContainer>
                <input
                  type="checkbox"
                  id="checkbox"
                  name="do_not_sell_info"
                  checked={formData.do_not_sell_info}
                  onChange={handleChange}
                />
                <label htmlFor="checkbox">
                  Do not sell my personal information
                </label>
              </CheckboxContainer>

              <CheckboxContainer>
                <input
                  type="checkbox"
                  id="checkbox_delete"
                  name="delete_my_info"
                  checked={formData.delete_my_info}
                  onChange={handleChange}
                />
                <label htmlFor="checkbox_delete">Delete my information</label>
              </CheckboxContainer>

              <CheckboxContainer>
                <input
                  type="checkbox"
                  id="checkbox_request"
                  name="request_my_data"
                  checked={formData.request_my_data}
                  onChange={handleChange}
                />
                <label htmlFor="checkbox_request">
                  Request information about the data that Media Resource Group
                  has about me
                </label>
              </CheckboxContainer>

              <Button disabled={loading}>
                {loading ? "Submitting..." : "Submit"}
              </Button>
              {error && <p style={{ color: "red" }}>{error}</p>}
            </form>
          </LeftSide>
        )}
        <RightSide>
          <div>
            <p style={{ fontWeight: 600, fontSize: "1.1rem" }}>Contact</p>
            <a
              style={{ color: "#003566" }}
              href="mailto:info@mrginc.com"
              target="_blank"
              rel="noreferer"
            >
              info@mrginc.com
            </a>
            <a style={{ color: "#003566" }} href="tel:2396313553">
              239-631-3553
            </a>
          </div>

          <div>
            <p style={{ fontWeight: 600, fontSize: "1.1rem" }}>Address</p>
            <p>2430 Vanderbilt Beach Rd. Ste. 108-558</p>
            <p>Naples, FL 34109</p>
          </div>

          <div>
            <a
              href="https://www.linkedin.com/company/media-resource-group/"
              target="_blank"
              rel="noreferrer"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
              </svg>
            </a>
          </div>
        </RightSide>
      </FormContainer>
    </Container>
  );
};

export default SellInfo;
