import styled, { keyframes } from "styled-components";
import { trackClick } from "../../utils";

const slideInAndGrow = keyframes`
  0% {
    transform: translateX(-100%) scale(0.5);
    opacity: 0;
  }
  50% {
    transform: translateX(0) scale(0.75);
    opacity: 1;
  }
  100% {
    transform: translateX(0) scale(1);
  }
`;

const slideInFromTop = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideInFromRight = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const Container = styled.section`
  position: relative;
  background-color: #003566;
  padding: 4rem 2rem;

  @media (max-width: 1249px) {
    padding: 2rem;
  }

  @media (max-width: 768px) {
    padding: 2rem 1rem;
    min-height: fit-content;
  }
`;

const Heading = styled.h2`
  color: white;
  font-size: 3.5rem;
  text-align: center;
  font-weight: 300;
  width: 100%;
  font-family: "DM Serif Display", serif;

  @media (max-width: 1249px) {
    font-size: 2.5rem;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const ContentSection = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 6rem;
`;

const LeftSection = styled.div`
  animation: ${slideInAndGrow} 0.75s ease-out forwards;
  img {
    width: 680px;
    border-radius: 3rem;

    @media (max-width: 1249px) {
      width: 520px;
    }

    @media (max-width: 768px) {
      width: 95%;
      margin-left: auto;
      margin-right: auto;
      display: block;
      height: auto;
    }
  }
`;

const RightSection = styled.div``;

const RightSectionStatistics = styled.div`
  display: flex;
  gap: 2rem;
  opacity: 0;
  animation: ${slideInFromTop} 0.5s ease-out 0.5s forwards;
`;

const RightSectionList = styled.ul`
  list-style: none;
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 1rem;
  opacity: 0;
  animation: ${slideInFromRight} 0.5s ease-in-out 1s forwards;

  li {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  svg {
    width: 24px;
    fill: #ffd60a;
  }

  p {
    color: white;
  }
`;

const Button = styled.button`
  background-color: #ffd60a;
  border: none;
  outline: none;
  border-radius: 100rem;
  padding: 1rem 1.8rem;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  width: 170px;
  margin-top: 4rem;
  color: black;

  &:hover {
    background-color: #c4e5f2;
  }
`;

const SecondSection = ({ scrollToContactForm }) => {
  return (
    <Container>
      <Heading>
        Driving results that
        <br /> matter.
      </Heading>

      <ContentSection>
        <LeftSection>
          <img
            src="https://cdn.prod.website-files.com/65272808fd070a45d9fa34ba/65272c6f7f42d1a827674ec9_White%20Horizontal-p-800.png"
            alt="MRG Logo"
          />
        </LeftSection>
        <RightSection>
          <RightSectionStatistics>
            <div>
              <p style={{ color: "white", fontSize: "3rem" }}>
                30<span style={{ fontWeight: 200 }}>+</span>
              </p>
              <p style={{ color: "white" }}>Years of experience</p>
            </div>
            <div>
              <p style={{ color: "white", fontSize: "3rem" }}>
                54<span style={{ fontWeight: 200 }}>+</span>
              </p>
              <p style={{ color: "white" }}>Happy Clients</p>
            </div>
            <div>
              <p style={{ color: "white", fontSize: "3rem" }}>
                5<span style={{ fontWeight: 200 }}>+</span>
              </p>
              <p style={{ color: "white" }}>Year average partnership</p>
            </div>
          </RightSectionStatistics>

          <RightSectionList>
            <li>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
              </svg>
              <p>Data-driven decision making</p>
            </li>
            <li>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
              </svg>
              <p>Transparent reporting</p>
            </li>
            <li>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
              </svg>
              <p>Proactive campaign management</p>
            </li>
          </RightSectionList>

          <Button
            onClick={() => {
              trackClick("button", "Contact Us Button");
              scrollToContactForm();
            }}
          >
            Contact Us
          </Button>
        </RightSection>
      </ContentSection>
    </Container>
  );
};

export default SecondSection;
