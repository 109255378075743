import styled from "styled-components";
import { trackClick } from "../../utils";

const Container = styled.section`
  background-color: #f5fbff;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1249px) {
    padding: 2rem;
  }

  @media (max-width: 768px) {
    padding: 1.5rem;
  }
`;

const Heading = styled.h2`
  color: #003566;
  font-size: 3.4rem;
  font-weight: 300;
  font-family: "DM Serif Display", serif;

  @media (max-width: 1249px) {
    font-size: 2.6rem;
  }

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

const Description = styled.p`
  color: #003566;
  font-size: 1.1rem;
  margin-top: 2rem;
  text-align: center;
  font-weight: 300;
  width: 100%;
  line-height: 2rem;

  @media (max-width: 768px) {
    font-size: 0.95rem;
    text-align: justify;
    line-height: 1.4rem;
  }
`;

const Button = styled.button`
  background-color: #ffd60a;
  border: none;
  outline: none;
  border-radius: 100rem;
  padding: 1rem 1.8rem;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  width: 170px;
  margin-top: 4rem;
  color: black;

  &:hover {
    background-color: #c4e5f2;
  }
`;

const SecondSection = ({ scrollToContactForm }) => {
  return (
    <Container>
      <Heading>Elevate Your Marketing</Heading>
      <Description>
        Media Resource Group is a direct response marketing firm that provides
        advertisers with
        <br />
        unique marketing campaigns reaching highly targeted consumers with the
        purpose of
        <br />
        providing a steady stream of new customer sales growth.
      </Description>

      <Description>
        Disciplines, media performance insights, creative analysis and a
        multi-lever optimization process <br /> applied to an array of sales
        growth opportunities.
      </Description>

      <Button
        onClick={() => {
          trackClick("button", "Contact Us Button");
          scrollToContactForm();
        }}
      >
        Contact Us
      </Button>
    </Container>
  );
};

export default SecondSection;
